@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------------------------ FONTS ------------------------------ */

@font-face {
  font-family: 'Moderat';
  src:
    url('/fonts/Moderat-Regular.woff') format('woff'),
    url('/fonts/Moderat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 300;
  src:
    url('/fonts/Moderat-Light.woff') format('woff'),
    url('/fonts/Moderat-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 500;
  src:
    url('/fonts/Moderat-Medium.woff') format('woff'),
    url('/fonts/Moderat-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 600;
  src:
    url('/fonts/Moderat-Semibold.woff') format('woff'),
    url('/fonts/Moderat-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Moderat';
  font-weight: 700;
  src:
    url('/fonts/Moderat-Bold.woff') format('woff'),
    url('/fonts/Moderat-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'PP Fragment';
  src:
    url('/fonts/PPFragment-GlareRegular.woff') format('woff'),
    url('/fonts/PPFragment-GlareRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'BDOGrotesk';
  src: url('/fonts/BDOGrotesk-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BDOGrotesk';
  font-weight: 300;
  src: url('/fonts/BDOGrotesk-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'BDOGrotesk';
  font-weight: 500;
  src: url('/fonts/BDOGrotesk-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'BDOGrotesk';
  font-weight: 600;
  src: url('/fonts/BDOGrotesk-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'BDOGrotesk';
  font-weight: 700;
  src: url('/fonts/BDOGrotesk-Bold.woff2') format('woff2');
}

/* ------------------------------ END FONTS ------------------------------ */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  .set-font-brand-rotation-serif {
    @apply font-brand-rotation-serif;
  }
}